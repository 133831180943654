<script setup lang="ts">

import { usePage } from '@inertiajs/vue3'
import CardHostelsPrice from '@/Pages/the-slp/components/the-shortcodes/card-hostels/CardHostelsPrice.vue'
import CardHostelsFeatures from '@/Pages/the-slp/components/the-shortcodes/card-hostels/CardHostelsFeatures.vue'
import CardHostelsOtaLinks from '@/Pages/the-slp/components/the-shortcodes/card-hostels/CardHostelsOtaLinks.vue'
import { OtaLinks } from '@/Interfaces/Common'
import CardHostelsEditLinks from '@/Pages/the-slp/components/the-shortcodes/card-hostels/CardHostelsEditLinks.vue'
import { EditLinks, SlpSlider } from '@/Pages/the-slp/components/interfaces/TheSlp'
import SeoTable from '@/Pages/the-slp/components/the-shortcodes/SeoTable.vue'
import CardHostelsSlider from '@/Pages/the-slp/components/the-shortcodes/card-hostels/CardHostelsSlider.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTrophy, faStar } from '@fortawesome/free-solid-svg-icons'
import TheMap from '@/Pages/the-slp/components/the-shortcodes/TheMap.vue'
import { MapData } from '@/Interfaces/Map'
import { Schema } from '@/Pages/the-home/interfaces/Schema'
import TheSchema from '@/Components/TheSchema.vue'

defineProps<{
    city: string
    items: {
        idAttr: string
        name: string
        rating: string
        specialText: string
        features?: []
        otaLinks: OtaLinks
        editLinks?: EditLinks
        slider: SlpSlider
        price: {
            dorm?: string
            priv?: string
        }
    }[]
    seoTable: {
        title: string
        items: []
    }
    map: {
        city: string
        items: MapData
    }
    schema?: Schema[]
}>()

const page = usePage()

</script>

<template>
    <div class="listingsList pt-3">
        <TheSchema v-if="schema" :schema="schema" />

        <div class="row vue-full-card-slider-wrap">
            <div v-for="(item, key) in items" :key class="col-lg-12 mb-5 listing listingLink listing">
                <h3 :id="item.idAttr" class="card-title h3">
                    <span class="icon-rounded bg-dark mb-3 text-white icon-rounded-sm">
                        {{ key + 1 }}
                    </span> {{ item.name }}
                </h3>

                <p class="article-metadata">
                    <span v-if="key === 0" class="listingAccolades mb-2 mr-4">
                        <FontAwesomeIcon :icon="faTrophy" class="text-warning" /> The #1 Hostel in {{ city }}!
                    </span>

                    <span class="mr-4">
                        <FontAwesomeIcon :icon="faStar" class="text-warning" /> Rating: <span class="font-weight-bold">
                            {{ item.rating }}</span>
                    </span>
                </p>

                <CardHostelsPrice
                    :="{
                            ...item.price,
                            ...item.otaLinks
                        }"
                />
                                                
                <div v-if="item.specialText" v-html="item.specialText" />

                <CardHostelsOtaLinks :="item.otaLinks" />

                <CardHostelsFeatures
                    v-if="item.features"
                    :city="city"
                    :items="item.features"
                    :name="item.name"
                />

                <CardHostelsEditLinks v-if="item.editLinks && !!page.props.user" :="item.editLinks" />

                <!--todo: add wishlist, compare-->
                <CardHostelsSlider :="item.slider" />
            </div>
        </div>
    </div>

    <TheMap :="map" />

    <SeoTable :="seoTable" />
</template>

<style scoped lang="scss">

</style>